import React from "react"

import SourcesList from "../SourcesList"
import { MessageText, MessageHeader, MessageActions } from "./components"

import ChatAssistantIcon from "../../../../../../../assets/img/chatAssistantIcon.png"

import "./style.scss"

export const AssistantChatMessage = ({
  message,
  onAddFeedback,
  isStreaming
}) => {
  return (
    <div className="assistant_message">
      <div className="message_left">
        <img className="message_user_icon" src={ChatAssistantIcon} />
      </div>
      <div className="message_right">
        <MessageHeader message={message} />
        <MessageText isStreaming={isStreaming} message={message} />
        <SourcesList sources={message.sources} />
        <MessageActions
          isStreaming={isStreaming}
          message={message}
          onAddFeedback={onAddFeedback}
        />
      </div>
    </div>
  )
}

export default AssistantChatMessage
