import { useCallback, useEffect, useRef } from "react"
import { constants } from "../redux"
import { useDispatch } from "react-redux"

const MESSAGE_EVENT = {
  MESSAGE: "message",
  CHUNK: "chunk",
  FULL_ANSWER: "full_answer",
  SOURCES: "sources",
  ERROR: "error"
}

export const useSendChatMessage = chat => {
  const dispatch = useDispatch()
  const controllerRef = useRef(null)

  const handleSendMessage = useCallback(
    message => {
      if (!controllerRef.current) {
        controllerRef.current = new AbortController()
      }

      if (chat) {
        dispatch({
          type: constants.CHAT_ASK_QUESTION,
          data: { chat_id: chat.id, message },
          signal: controllerRef.current.signal,
          onMessage: ev => {
            switch (ev.event) {
              case MESSAGE_EVENT.MESSAGE: {
                dispatch({
                  type: constants.CHAT_ADD_MESSAGE_SUCCESS,
                  response: JSON.parse(ev.data)
                })
                break
              }
              case MESSAGE_EVENT.CHUNK:
                dispatch({
                  type: constants.CHAT_CHUNK_ASSISTANT_MESSAGE,
                  response: ev.data
                })
                break
              case MESSAGE_EVENT.FULL_ANSWER:
                dispatch({
                  type: constants.CHAT_UPDATE_ASSISTANT_MESSAGE,
                  response: { id: JSON.parse(ev.data).id }
                })
                break
              case MESSAGE_EVENT.SOURCES:
                dispatch({
                  type: constants.CHAT_UPDATE_ASSISTANT_MESSAGE,
                  response: { sources: JSON.parse(ev.data) }
                })
                break
              case MESSAGE_EVENT.ERROR:
                dispatch({
                  type: constants.CHAT_UPDATE_ASSISTANT_MESSAGE,
                  response: { error: ev.data }
                })
                break
              default:
                break
            }
          },
          onClose: () => {
            dispatch({
              type: constants.CHAT_STOP_ASSISTANT_MESSAGE_STREAMING
            })
          }
        })
      }
    },
    [dispatch, chat]
  )

  useEffect(() => {
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort() // Cancel the event stream
        controllerRef.current = null // Optionally clear the ref to avoid retaining unnecessary references
      }
    }
  }, [])

  return {
    handleSendMessage
  }
}
