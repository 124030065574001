import { useEffect, useRef } from "react"

export const useChatAutoScroll = (messages, isStreaming) => {
  const chatMessagesContainerRef = useRef(null)

  useEffect(() => {
    if (!messages.length) return

    chatMessagesContainerRef.current.scrollTo({
      top: chatMessagesContainerRef.current.scrollHeight,
      behavior: "smooth"
    })
  }, [messages, isStreaming])

  return {
    chatMessagesContainerRef
  }
}
