import React from "react"

import { AssistantChatMessage, UserChatMessage } from "./components"

import "./style.scss"

const MESSAGE_TYPE = {
  USER: "user",
  ASSISTANT: "assistant"
}

const RenderMessage = ({ message, onAddFeedback, isStreaming }) => {
  if (message.type === MESSAGE_TYPE.USER) {
    return <UserChatMessage message={message} key={message.id} />
  }

  return (
    <AssistantChatMessage
      key={message.id}
      message={message}
      onAddFeedback={onAddFeedback}
      isStreaming={isStreaming}
    />
  )
}

const ChatMessages = ({ messages, onAddFeedback, isStreaming }) => {
  return (
    <div className="chat-messages">
      <div className="chat-messages-content">
        {messages.map((m, index) => (
          <RenderMessage
            message={m}
            key={m.id}
            onAddFeedback={onAddFeedback}
            isStreaming={isStreaming && index === messages.length - 1}
          />
        ))}
      </div>
    </div>
  )
}

export default ChatMessages
