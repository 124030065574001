import moment from "moment"
import { toast } from "react-hot-toast"

import { FIND_UDERSCORE_REG_EXP, REMOVE_FILE_EXTENSION_REG_EXP } from "./regExp"

export const getState = state => {
  const keys = Object.keys(state)
  const values = Object.values(state)
  let data = {}
  keys.forEach((item, i) => {
    data = {
      ...data,
      [item]: values[i].value
    }
  })
  return data
}

export const characterEllipsis = (string, characters = 2) => {
  if (string.length > characters * 2) {
    return (
      string.substr(0, characters) +
      "***" +
      string.substr(string.length - characters, string.length)
    )
  }
  return string
}

export const nonAdminErrorMessage = message => {
  if (message === "Your are not a admin") {
    toast.error("No data available for non admin user")
  } else {
    toast.error("Request failed")
  }
}

export const Logout = async () => {
  await localStorage.clear()
  window.location.reload()
}

export const formatDate = (date, format) => {
  return moment(date).format(format)
}

export const copyToClipboard = text => {
  navigator.clipboard.writeText(text)
}

export const showSuccessToast = message => {
  toast.success(message)
}

export const getFileName = filePath => {
  const parts = filePath.split("/")
  const fileName = parts[parts.length - 1]

  const fileNameWithoutExtension = (fileName || "").replace(
    REMOVE_FILE_EXTENSION_REG_EXP,
    ""
  )

  return (fileNameWithoutExtension || "").replace(FIND_UDERSCORE_REG_EXP, " ")
}
