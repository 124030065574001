import React from "react"

import "./style.scss"
import { formatDate } from "utils/functions"
import { date_format } from "variables/date"

export const UserChatMessage = ({ message }) => {
  return (
    <div className="user_message">
      <div className="message_header">
        <span className="message_user_name">You</span>
        <span className="message_time">
          {formatDate(message.created_at, date_format.time)}
        </span>
      </div>
      <div className="message_content">{message.message}</div>
    </div>
  )
}

export default UserChatMessage
