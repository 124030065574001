import { useSelector } from "react-redux"
import { copyToClipboard } from "utils/functions"
import { showSuccessToast } from "utils/functions"

export const useCopyChatConversation = () => {
  const { chat } = useSelector(state => state.VisualChatReducer)

  const handleCopyConversation = () => {
    copyToClipboard(prepareData(chat))
    showSuccessToast("Conversation copied successfully")
  }

  return {
    handleCopyConversation
  }
}

const prepareData = chat => {
  const messages = (chat?.messages || []).map(item => {
    return {
      message: item.message
    }
  })

  return JSON.stringify(messages)
}
