import React from "react"

import "./style.scss"

export const MessageText = ({ isStreaming, message }) => {
  if (isStreaming && !message.message) {
    return (
      <div className="assistant_message_text_streaming loading">
        I'm researching PRC Macro archives
      </div>
    )
  }

  return (
    <div className="assistant_message_text">
      <div>{message.message}</div>
      {message.error ? (
        <div className="assistant_message_text_error">{message.error}</div>
      ) : null}
    </div>
  )
}

export default MessageText
